import React from "react";
import { Link } from "gatsby";
import { BsArrowRight } from "@react-icons/all-files/bs/BsArrowRight";
import * as styles from "./cases.module.css";
import Check from "../../images/check.png";
import Case1 from "../../images/case1.png";
import Case2 from "../../images/solution.png";
import Case3 from "../../images/development.png";
import Case5 from "../../images/result.png";
const CaseStudy = () => {
  return (
    <div>
      <div className={styles.cs_header}>
        <h1>
          {" "}
          <span>Enjoy Coin</span>{" "}
        </h1>
      </div>
      <div className={styles.challenge}>
        <div className={styles.challenge_head}>
          <img src={Case1} alt="" />
          <h1>Challenge</h1>
        </div>

        <p>
          Enjoy Coin's founders came to Zector with an idea to create a mobile
          application that provides a better method for services workers to
          receive their tips. There was no code written just a thought that the
          Enjoy Coin team was looking to bring to reality.
          <br />
          <br />
          The Challenging part was that the Enjoy Coin team had a vision of what
          they wanted, but the specifics were not present.
          <br />
          <br />
          So we agree to start with:
          <br />
          <br />
        </p>
        <ul>
          <div className={styles.list_main}>
            <img src={Check} alt="" />
            <li>
              {" "}
              Outlining Enjoy Coin's key goals of the project and expectations.
            </li>
          </div>
          <div className={styles.list_main}>
            <img src={Check} alt="" />
            <li>
              {" "}
              Creating user personas, and other specifics catered to how the
              application will function.
            </li>
          </div>
          <div className={styles.list_main}>
            <img src={Check} alt="" />
            <li>
              Focusing on the UI/UX design for each aspect of the project.
            </li>
          </div>
        </ul>
      </div>

      <div className={styles.challenge2}>
        <div className={styles.challenge2_head}>
          <img src={Case2} alt="" />
          <h1>Solution</h1>
        </div>

        <p>
          After meeting with the Enjoy Coin team we had a clear understanding of
          what needed to be done. Our team quickly provided well designed
          mockups of the application which were well received.
          <br />
          <br />
          The diversity of skillsets within our team allowed us to not only
          select the proper language to develop the application, but we were
          also able to confirm the best platform to host the application,
          Identify the proper security controls, and compliance requirements for
          the application in scope.
          <br />
          <br />
          Our team Is based in Maryland, so we were able to work remotely around
          the clock to meet our clients deadlines.
        </p>
      </div>

      <div className={styles.challenge3}>
        <div className={styles.challenge3_head}>
          <img src={Case3} alt="" />
          <h1>Development</h1>
        </div>
        <div className={styles.containDev}>
          <div>
            <div className={styles.paraWrap}>
              <p className={styles.designPara}>
                We developed a native application for our client from scratch.
                We listened to all the key details that we captured from our
                scoping call which in return created the best opportunity for us
                to wow our client.
              </p>
            </div>
            <div className={styles.quoteWrap}>
              <h5 className={styles.quote}>
                We are proud to highlight some of the product features that we
                implemented
              </h5>
            </div>
          </div>
          <div className={styles.keyPoints}>
            <span>Contactless tipping</span>
            <span>Financial literacy</span>
            <span>Crypto Investments</span>
            <span>Service workers can receive tips in crypto</span>
            <span>Users can save crypto and gain interest</span>
            <span>Service workers can receive their tips instantly</span>
          </div>
        </div>
      </div>
      <div className={styles.challenge4}>
        <div className={styles.challenge4_head}>
          <img src={Case2} alt="" />
          <h1>Design</h1>
        </div>
        <p>
          Our team was able to capture a modern feel and meet the goals of our
          client
          <br />
          <br />
          We created a sleek design that captivates our users with innovative
          features to keep our the users inside the application.
        </p>
      </div>
      <div className={styles.challenge5}>
        <div className={styles.challenge5_head}>
          <img src={Case5} alt="" />
          <h1>Result</h1>
        </div>
        <p>
          Together with the help of the Enjoy Coin team we were able to create a
          modern, fully functional mobile application that will be used by many
          users to directly tips service workers.
          <br />
          <br />
          We were glad to work on this project and help our client change the
          world.
        </p>
      </div>
      <div className={styles.letsbuild_main}>
        <div className={styles.letsbuild_head}>
          <h1>
            Let's build something <span>together</span>
          </h1>
        </div>
        <div className={styles.letsbuild_btn}>
          <Link to="/contact" style={{ textDecoration: `none` }}>
            <span style={{ textDecoration: `none` }}>
              {" "}
              Tell us about your project{" "}
            </span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CaseStudy;
