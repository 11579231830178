// extracted by mini-css-extract-plugin
export const cs_header = "cases-module--cs_header--1--Zd";
export const std = "cases-module--std--1rGV4";
export const hash = "cases-module--hash--2jUbm";
export const number = "cases-module--number--3KAFN";
export const caseStudyy = "cases-module--caseStudyy--Qa1zU";
export const challenge = "cases-module--challenge--8GU9k";
export const challenge_head = "cases-module--challenge_head--BXK7Y";
export const list_main = "cases-module--list_main--M8Nsb";
export const challenge2 = "cases-module--challenge2--1z60_";
export const challenge2_head = "cases-module--challenge2_head--E-ZdT";
export const challenge3 = "cases-module--challenge3--Id4Ko";
export const challenge3_head = "cases-module--challenge3_head--2lhGO";
export const challenge4 = "cases-module--challenge4--3EBpG";
export const challenge4_head = "cases-module--challenge4_head--1o2gE";
export const challenge5 = "cases-module--challenge5--3-g5S";
export const challenge5_head = "cases-module--challenge5_head--1yXIN";
export const letsbuild_head = "cases-module--letsbuild_head--2amj8";
export const letsbuild_btn = "cases-module--letsbuild_btn--3cVA5";
export const designPara = "cases-module--designPara--2oqG4";
export const paraWrap = "cases-module--paraWrap--3453N";
export const containDev = "cases-module--containDev--1gCCd";
export const quoteWrap = "cases-module--quoteWrap--3Kd4Q";
export const quote = "cases-module--quote--FyyMW";
export const keyPoints = "cases-module--keyPoints--1vyfS";
export const lmWrapRest = "cases-module--lmWrapRest--1YVFU";
export const lmWrap = "cases-module--lmWrap--2sNZK";
export const learnMore = "cases-module--learnMore--16ny_";