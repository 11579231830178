import React from "react";
import * as styles from "./header.module.css";
import { Link } from "gatsby";


export default function HeaderCompo({ headerTitle, headerBtn, headerbg }) {
  return (
    <div className={`${styles.nav_header} d-flex justify-center item-center`}>
      <div className={`${styles.header_bg} ps-absolute`}>
        <img alt="" src={headerbg} />
      </div>
      <div className={`${styles.header_content} m-auto`}>
        <h1
          className={`${styles.header_title} text-white text-center mb-30 pl-15 pr-15`}
          style={{fontFamily: `Montserrat, sans-serif`}}
        >
          {headerTitle}
        </h1>
        <Link to="/contact" className="btn bs-bb no-underline text-white m-auto" style={{fontFamily: `Montserrat, sans-serif`}}>
          {headerBtn}
        </Link>
      </div>
    </div>
  );
}
